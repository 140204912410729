import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  Business,
  Delete,
  Save,
  Cancel,
  Send,
  InfoOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState, useCallback, useContext } from "react";
import i18n from "../../../locales/i18n";
import { PartnersAPI, CountriesAPI, ClientesAPI } from "../../../services/API";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AuthService from "../../../services/AuthService";
import {
  DetailTabs_Style,
  Common_Style,
  globalStatusColors,
} from "../../../assets/css/Styles";
import { PlantasAPI } from "../../../services/API";
import { SnackbarSuccess, SnackbarWarning } from "../../../helpers/Common";
import AppContext from "../../../contexts/AppContext";

function CardPlanta(props) {
  const {
    planta,
    setPlanta,
    errors,
    handleInputChange,
    id,
    deleteDialog,
    updatePlanta,
    addPlanta,
    //IsPartnerPlanta,
    keyuser,
    resetPlanta,
    handlePlantInfoBySalesforce,
    setErrors,
    readOnly,
  } = props;
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const isGestor = AuthService.isGestorFromPartnerForClient(planta.clienteId);
  const isDSC = AuthService.hasRoleDSC();
  const isPartner = AuthService.hasRolePartner();
  const currentUserHasRoleEndCustomer = AuthService.hasRoleEndCustomer();
  const isReadOnly = currentUserHasRoleEndCustomer || readOnly;
  const isGestorFromPartner = AuthService.isGestorFromPartner(planta.partnerId);
  const [cliente, setCliente] = useState(undefined);
  const [partnersOptions, setPartnersOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();
  const [infoBySalesforce, setInfoBySalesforce] = useState(false);
  const [provinceEmpty, setProvinceEmpty] = useState(false);
  const [salefoceLength, setSalefoceLength] = useState(0);
  const [salesforceBtnLoading, setSalesforceBtnLoading] = useState(false);

  const getPartnerOptions = useCallback(async () => {
    if ((keyuser || isDSC) && !id) {
      return await PartnersAPI.getOfCliente(planta.clienteId)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          var partners = [];
          data.forEach((element) => {
            partners.push({ id: element.id, name: element.razonSocial });
          });
          setPartnersOptions(partners);
        })
        .catch(() => {});
    }
  }, [id, isDSC, keyuser, planta.clienteId]);

  const getCliente = useCallback(async () => {
    ClientesAPI.get(planta.clienteId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        if (data.isTest) {
          setPlanta((old) => ({ ...old, isTest: true }));
        }
        setCliente(data);
      });
  }, [planta.clienteId, setPlanta]);

  const btnEvent_checkSalesforceId = () => {
    if (planta?.salesforceId?.length !== 18) {
      setErrors({ salesforceIBadLength: true });
      return;
    }
    if (!planta.salesforceId) {
      setInfoBySalesforce(false);
    } else {
      setSalesforceBtnLoading(true);
      // Obtenr la info de Salesforce
      PlantasAPI.getPlantaInfoBySalesForce(planta.salesforceId)
        .then(async (response) => {
          if (response.ok) {
            const data = await response.json();
            // Here set data into planta object
            handlePlantInfoBySalesforce({
              infoBySalesforce: true,
              denominacion: data.denominacion,
              direccion: data.direccion,
              cp: data.cp,
              poblacion: data.poblacion,
              provincia: data.provincia,
              countryId: data.countryId,
            });
            setProvinceEmpty(!data.provincia || data.provincia?.trim() === "");
            setSalesforceBtnLoading(false);
            SnackbarSuccess(
              setGlobal,
              i18n.t("planta.info_from_salesforce_found")
            );
            setSalesforceBtnLoading(false);
          } else if (response.status === 404) {
            // Not found
            handlePlantInfoBySalesforce({
              infoBySalesforce: false,
            });
            SnackbarWarning(
              setGlobal,
              i18n.t("planta.info_from_salesforce_not_found")
            );
            setSalesforceBtnLoading(false);
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          setSalesforceBtnLoading(false);
        });
    }
  };

  const handleInputChangeSalesforceId = (event) => {
    let inputValue = event.target.value;
    event.target.value = inputValue
      .replace(/\s+/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .trim();
    setSalefoceLength(event.target.value.length);
    handleInputChange(event);
  };

  useEffect(() => {
    getPartnerOptions();
    getCliente();
  }, [getCliente, getPartnerOptions]);

  useEffect(() => {
    CountriesAPI.get()
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(response.json());
        }
      })
      .then((data) => {
        data.sort((a, b) =>
          i18n.t(`country.${a.name}`).localeCompare(i18n.t(`country.${b.name}`))
        );
        setCountries(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setInfoBySalesforce(planta.infoBySalesforce);
  }, [planta.infoBySalesforce]);

  return (
    <Card>
      <div className={styles.mainCardFormContainer}>
        <div className={styles.formCardContainer}>
          <form autoComplete="off">
            <div className={styles.mainCardFormHeader}>
              <CardHeader
                title={planta.denominacion}
                subheader={i18n.t("planta.tituloCard")}
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    <Business />
                  </Avatar>
                }
              />
            </div>
            <div>
              {infoBySalesforce && (
                <div
                  className={styles.labelInfoFromSalesforce}
                  style={{ backgroundColor: globalStatusColors.infoColor }}
                >
                  <InfoOutlined className={commonStyles.bannerIcon} />
                  {i18n.t("planta.helpText_salesforce")}
                </div>
              )}
              {!infoBySalesforce && id && (
                <div
                  className={styles.labelInfoFromSalesforce}
                  style={{ backgroundColor: globalStatusColors.warningColor }}
                >
                  <ReportProblemOutlined className={commonStyles.bannerIcon} />
                  {i18n.t("planta.helpText_Notsalesforce")}
                </div>
              )}
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                {/* Salesforce ID */}
                <TextField
                  required
                  fullWidth
                  className={styles.materialTextField}
                  value={planta.salesforceId || ""}
                  label={i18n.t("planta.salesforceId")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 18,
                    readOnly: isReadOnly,
                  }}
                  error={
                    errors["salesforceId"] !== undefined ||
                    errors["salesforceIdDuplicated"] !== undefined ||
                    errors["salesforceRegistered"] !== undefined ||
                    errors["salesforceIBadLength"] !== undefined
                  }
                  helperText={
                    salefoceLength > 0 &&
                    !errors["salesforceId"] &&
                    !errors["salesforceRegistered"] &&
                    !errors["salesforceIdDuplicated"]
                      ? i18n.t("planta.salesforceLength", {
                          salefoceLength,
                        })
                      : errors["salesforceId"]
                      ? i18n.t("textField.required")
                      : errors["salesforceRegistered"]
                      ? i18n.t("planta.salesforceRegistered")
                      : errors["salesforceIdDuplicated"]
                      ? i18n.t("planta.salesforceIdDuplicated")
                      : errors["salesforceIBadLength"]
                      ? i18n.t("planta.salesforceIBadLength")
                      : null
                  }
                  name="salesforceId"
                  onChange={handleInputChangeSalesforceId}
                />
              </div>
              <div className={styles.flexCellForm}>
                {((!id && (keyuser || isDSC || isGestor)) ||
                  (!isReadOnly && (keyuser || isGestorFromPartner))) &&
                  (salesforceBtnLoading ? (
                    <Button
                      variant="contained"
                      className={styles.primaryFormButton}
                      disabled={true}
                    >
                      <CircularProgress size={24} color="inherit" />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={styles.primaryFormButton}
                      onClick={() => btnEvent_checkSalesforceId()}
                    >
                      <Send className={styles.saveIcon} />
                      {i18n.t("planta.btn_salesforce_check")}
                    </Button>
                  ))}
              </div>
              <div className={styles.flexCellForm}></div>
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                {/* Name */}
                <TextField
                  required
                  fullWidth
                  className={styles.materialTextField}
                  value={planta.denominacion || ""}
                  label={i18n.t("planta.denominacion")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: isReadOnly || infoBySalesforce,
                  }}
                  error={
                    errors["Denominacion"] !== undefined ||
                    errors["Denominacion_unica"] !== undefined
                  }
                  helperText={
                    errors["Denominacion"]
                      ? i18n.t("textField.required")
                      : errors["Denominacion_unica"]
                      ? i18n.t("planta.nombreUsado")
                      : null
                  }
                  name="denominacion"
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.flexCellForm}>
                {/* Country */}
                {(isReadOnly || infoBySalesforce) && (
                  <TextField
                    required
                    fullWidth
                    className={styles.materialTextField}
                    value={
                      planta.countryId && countries.length > 0
                        ? countries?.find(
                            (c) =>
                              c.id.toString() === planta.countryId.toString()
                          )?.name
                        : null
                    }
                    label={i18n.t("planta.pais")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                    error={errors["CountryId"] !== undefined}
                    helperText={
                      errors["CountryId"] ? i18n.t("textField.required") : null
                    }
                    name="countryId"
                  />
                )}
                {!isReadOnly && !infoBySalesforce && (
                  <Autocomplete
                    open={undefined}
                    autoComplete={false}
                    closeIcon={undefined}
                    freeSolo={false}
                    fullWidth
                    autoHighlight
                    options={countries}
                    getOptionLabel={(c) =>
                      i18n.t(`country.${c.name || "null"}`)
                    }
                    value={
                      planta.countryId && countries.length > 0
                        ? countries.find(
                            (c) =>
                              c.id.toString() === planta.countryId.toString()
                          )
                        : null
                    }
                    className={styles.materialTextField}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={i18n.t("planta.pais")}
                        error={errors["CountryId"] !== undefined}
                        required
                        name="countryId"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: false,
                        }}
                        helperText={
                          errors["CountryId"]
                            ? i18n.t("textField.required")
                            : null
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      handleInputChange({
                        target: {
                          name: "countryId",
                          value: newValue ? newValue.id : null,
                        },
                      });
                    }}
                    renderOption={(c) => (
                      <ul>
                        <MenuItem value={c} key={c.id.toString()}>
                          {i18n.t(`country.${c.name}`)}
                        </MenuItem>
                      </ul>
                    )}
                  />
                )}
              </div>
              <div className={styles.flexCellForm}>
                {/* City */}
                <TextField
                  required
                  fullWidth
                  className={styles.materialTextField}
                  value={planta.poblacion || ""}
                  label={i18n.t("planta.poblacion")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: isReadOnly || infoBySalesforce,
                  }}
                  error={errors["Poblacion"] !== undefined}
                  helperText={
                    errors["Poblacion"] ? i18n.t("textField.required") : null
                  }
                  name="poblacion"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                {/* Address */}
                <TextField
                  required
                  fullWidth
                  className={styles.materialTextField}
                  value={planta.direccion || ""}
                  label={i18n.t("planta.direccion")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: isReadOnly || infoBySalesforce,
                  }}
                  error={errors["Direccion"] !== undefined}
                  helperText={
                    errors["Direccion"] ? i18n.t("textField.required") : null
                  }
                  name="direccion"
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.flexCellForm}>
                {/* Province */}
                <TextField
                  fullWidth
                  className={styles.materialTextField}
                  value={planta.provincia || ""}
                  label={i18n.t("planta.provincia")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly:
                      !provinceEmpty && (isReadOnly || infoBySalesforce),
                  }}
                  error={errors["Provincia"] !== undefined}
                  helperText={
                    errors["Provincia"] ? i18n.t("textField.required") : null
                  }
                  name="provincia"
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.flexCellForm}>
                {/* ZIP code */}
                <TextField
                  fullWidth
                  className={styles.materialTextField}
                  value={planta.cp || ""}
                  label={i18n.t("planta.cp")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: isReadOnly || infoBySalesforce,
                  }}
                  error={errors["CP"] !== undefined}
                  helperText={
                    errors["CP"] ? i18n.t("textField.required") : null
                  }
                  name="cp"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {(keyuser || isDSC) && !id && (
              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <FormControl
                    fullWidth
                    required
                    className={styles.materialTextField}
                  >
                    <TextField
                      select
                      label={i18n.t("planta.partner")}
                      value={planta.partnerId || ""}
                      className={styles.materialTextField}
                      required
                      name="partnerId"
                      onChange={handleInputChange}
                      error={errors["PartnerId"] !== undefined}
                      inputProps={{
                        readOnly: isReadOnly,
                      }}
                    >
                      {partnersOptions.map((p) => (
                        <MenuItem value={p.id} key={p.id}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </div>
              </div>
            )}

            {(isDSC || keyuser || isPartner) && (
              <div className={styles.flexCellForm}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isTest"
                      disabled={cliente?.isTest}
                      className={styles.checkBoxForm}
                      checked={planta.isTest || cliente?.isTest || false}
                      onChange={handleInputChange}
                    />
                  }
                  label={i18n.t("planta.isTest")}
                />
              </div>
            )}

            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                {((!id && (keyuser || isDSC || isGestor)) ||
                  (!isReadOnly && (keyuser || isGestorFromPartner))) && (
                  <>
                    <Button
                      variant="contained"
                      className={styles.primaryFormButton}
                      onClick={() => {
                        if (planta?.salesforceId?.length !== 18) {
                          setErrors({ salesforceIBadLength: true });
                          return;
                        }
                        if (
                          !planta.provincia ||
                          planta.provincia?.trim() !== ""
                        ) {
                          setProvinceEmpty(false);
                        }
                        if (id) {
                          updatePlanta();
                        } else {
                          addPlanta();
                        }
                      }}
                    >
                      <Save className={styles.saveIcon} />
                      {i18n.t("common.save")}
                    </Button>
                    <Button
                      variant="outlined"
                      className={styles.secondaryFormButton}
                      onClick={() => {
                        resetPlanta();
                        setSalefoceLength(0);
                      }}
                    >
                      <Cancel className={styles.saveIcon} />
                      {i18n.t("common.cancel")}
                    </Button>
                    {/* {id && !isGestorFromPlant &&( */}
                    {id && (
                      <Button
                        variant="outlined"
                        className={styles.secondaryFormButton}
                        onClick={() => deleteDialog.current.open()}
                      >
                        <Delete className={styles.deleteIcon} />
                        {i18n.t("common.delete")}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </Card>
  );
}

export default CardPlanta;
