import React, { useEffect, useState, useContext, useCallback } from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { PlantasAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import AuthService from "../../../services/AuthService";
import AppContext from "../../../contexts/AppContext";
import { Common_Style, CardList_Style } from "../../../assets/css/Styles";
import CardPlantClient from "../../../components/molecule/cardPlantClient/cardPlantClient";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FormControl,
  MenuItem,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  ExpandMoreOutlined,
  Clear,
  Check,
  DateRange,
} from "@material-ui/icons";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import moment from "moment";
import { es, enUS as en, de, fr, it, pl } from "date-fns/locale";

// Tema para el calendario
const theme = createMuiTheme({
  overrides: {
    // @ts-ignore
    MuiPickersDay: {
      day: {
        color: "#484848", // Cambia el color del día
      },
      daySelected: {
        backgroundColor: "#E2000F", // Color del día seleccionado
        "&:hover": {
          backgroundColor: "#B6000F", // Cambia el color de hover sobre el día seleccionado
        },
      },
      current: {
        color: "#B6000F", // Color del día actual
      },
    },

    MuiPickersYear: {
      root: {
        color: "#484848", // Cambia el color del año no seleccionado
        "&:hover": {
          color: "#B6000F", // Cambia el color de hover sobre el año
        },
        "&:focus": {
          color: "#B6000F", // Cambia el color de focus sobre el año
        },
      },
      yearSelected: {
        color: "#E2000F", // Cambia el color del año seleccionado
        fontWeight: "bold", // Puedes ajustar el peso del año seleccionado si quieres
        "&:hover": {
          color: "#B6000F", // Cambia el color de hover sobre el año
        },
        "&:focus": {
          color: "#B6000F", // Cambia el color de focus sobre el año
        },
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#E2000F", // Color de fondo de la barra del calendario
      },
    },
    MuiDialogActions: {
      root: {
        "& button": {
          color: "#303030", // Color de los botones de acción
          textTransform: "none", // Evita que el texto esté en mayúsculas
        },
      },
    },
  },
});

//Para los estilos para los inputs de fecha
const useStyles = makeStyles({
  underline: {
    "&:before": {
      borderBottomColor: "black !important",
    },
    "&:after": {
      borderBottomColor: "black !important",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottomColor: "black !important",
    },
  },
});
export default function PlantasList(props) {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [plantas, setPlantas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isNewQuery, setIsNewQuery] = useState(false);
  const [hasMorePlants, setHasMorePlants] = useState(true);
  const [isFirstInput, setIsFirstInput] = useState(false);
  const [statusFilter, setStatusFilter] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const commonStyles = Common_Style();
  const styles = CardList_Style();
  const inputstyles = useStyles();
  const [acordionExpanded, setAcordionExpanded] = useState(false);
  const locale = i18n.locale || i18n.defaultLocale;
  const [localeDate, setLocaleDate] = useState(en);
  //getPlantas
  const getPlantas = useCallback(
    (actualLength, searchQuery, isNewQuery, filter, startDate, endDate) => {
      setLoading(true);
      PlantasAPI.getInfinitePlants(
        actualLength,
        searchQuery,
        filter,
        startDate,
        endDate
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          if (isNewQuery) {
            setPlantas(data.plantas);
            setIsNewQuery(false);
          } else {
            setPlantas((old) => old.concat(data.plantas));
          }
          setHasMorePlants(data.hasMorePlants);
          AuthService.updateUserAccessPermisions(data.userAccessPermissions);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    },
    []
  );

  //Busqueda con delay
  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (isFirstInput) {
        setPlantas([]);
        setIsNewQuery(true);
        getPlantas(0, searchQuery, true, statusFilter);
      }
    }, 600);
    return () => clearTimeout(searchDelay);
  }, [searchQuery, getPlantas, isFirstInput, statusFilter]);

  //Para las traducciones para el calendario de los selectores
  useEffect(() => {
    switch (locale) {
      case "es":
        setLocaleDate(es);
        break;

      case "de":
        setLocaleDate(de);
        break;

      case "fr":
        setLocaleDate(fr);
        break;

      case "it":
        setLocaleDate(it);
        break;

      case "pl":
        setLocaleDate(pl);
        break;

      default:
        setLocaleDate(en);
    }
  }, [locale]);

  //Primer getPlantas
  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
    if (
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_PlantasDetails !== undefined
    ) {
      window["AltidVars"].Tabs_PlantasDetails = undefined;
    }
    //Limpeza de filtros de drives
    localStorage.removeItem("savedFilters");
    localStorage.removeItem("storageZoneInfo");
    getPlantas();
    setGlobal((prev) => ({
      ...prev,
      pageTitle: i18n.t("planta.tituloPaginaLista"),
    }));
  }, [getPlantas, setGlobal]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleCloseAcordion = () => {
    setAcordionExpanded(!acordionExpanded);
  };

  const cleanDates = () => {
    //para actualizar solo si hay fechas en el input
    if (startDate || endDate) {
      getPlantas(0, searchQuery, true);
    }
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <RelativeBackdrop hidden={!loading}>
      <div className={commonStyles.mainContainer}>
        <div className={commonStyles.searchFieldContainer}>
          <SearchField
            inputBaseProps={{
              placeholder: i18n.t("common.search"),
              value: searchQuery,
              onChange: (event) => {
                setIsFirstInput(true);
                setSearchQuery(event.target.value);
              },
            }}
          />
          <FormControl
            className={styles.materialTextField}
            fullWidth
            variant="filled"
            style={{ maxWidth: "35%" }}
          >
            <TextField
              select
              label={i18n.t("planta.filterregisters.label")}
              value={statusFilter}
              className={styles.materialTextField}
              defaultValue={0}
              onChange={(e) => {
                const valueNumber = Number.parseInt(e.target.value.toString());
                setIsFirstInput(true);
                setStatusFilter(valueNumber);
                setStartDate(null);
                setEndDate(null);
              }}
              inputProps={{
                name: "statusFilter",
              }}
            >
              <MenuItem value={0}>
                {i18n.t("planta.filterregisters.alphabeticalSorting")}
              </MenuItem>
              <MenuItem value={1}>
                {i18n.t("planta.filterregisters.chronologicalSorting")}
              </MenuItem>
            </TextField>
          </FormControl>
          {statusFilter === 1 && (
            <div>
              <Accordion
                expanded={acordionExpanded}
                onChange={handleCloseAcordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  id="panel1a-header"
                >
                  <div className={commonStyles.plantListAccordionTitle}>
                    <DateRange fontSize="default" />
                    {i18n.t("planta.filterregisters.menuButton")}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={localeDate}
                    >
                      <div
                        className={
                          commonStyles.plantListAccordionDetailsContainer
                        }
                      >
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-startDate"
                          label={i18n.t("planta.filterregisters.startDate")}
                          format="dd/MM/yyyy"
                          value={startDate}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="dd/MM/yyyy"
                          okLabel={i18n.t("common.confirmar")}
                          cancelLabel={i18n.t("common.cancelar")}
                          invalidDateMessage={i18n.t(
                            "planta.filterregisters.invalidDateMessage"
                          )}
                          minDateMessage={i18n.t(
                            "planta.filterregisters.notValidDate"
                          )}
                          maxDateMessage={i18n.t(
                            "planta.filterregisters.notValidDate"
                          )}
                          InputProps={{
                            classes: {
                              underline: inputstyles.underline,
                            },
                          }}
                          className={styles.materialTextField}
                        />
                        <div> __ </div>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label={i18n.t("planta.filterregisters.endDate")}
                          format="dd/MM/yyyy"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={endDate}
                          placeholder="dd/MM/yyyy"
                          onChange={handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          okLabel={i18n.t("common.confirmar")}
                          cancelLabel={i18n.t("common.cancelar")}
                          invalidDateMessage={i18n.t(
                            "planta.filterregisters.invalidDateMessage"
                          )}
                          minDateMessage={i18n.t(
                            "planta.filterregisters.notValidDate"
                          )}
                          maxDateMessage={i18n.t(
                            "planta.filterregisters.notValidDate"
                          )}
                          InputProps={{
                            classes: {
                              underline: inputstyles.underline,
                            },
                          }}
                          className={styles.materialTextField}
                        />
                        <div
                          className={
                            commonStyles.plantListAccordionButtonsContainer
                          }
                        >
                          <Button
                            onClick={() =>
                              getPlantas(
                                0,
                                searchQuery,
                                true,
                                1,
                                startDate,
                                endDate
                              )
                            }
                            variant="contained"
                            startIcon={<Check />}
                            className={commonStyles.primaryButtonRaw}
                            disabled={!startDate || !endDate}
                          >
                            {i18n.t("common.confirmar")}
                          </Button>
                          <Button
                            onClick={cleanDates}
                            variant="outlined"
                            startIcon={<Clear />}
                            className={commonStyles.secondaryButtonRaw}
                          >
                            {i18n.t("common.clear")}
                          </Button>
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>

        <InfiniteScroll
          scrollableTarget="main-content"
          className={commonStyles.cardsContainerBase}
          style={{ overflow: "ignore" }}
          dataLength={plantas.length}
          next={() =>
            getPlantas(
              plantas.length,
              searchQuery,
              isNewQuery,
              statusFilter,
              startDate,
              endDate
            )
          }
          hasMore={hasMorePlants}
          loader
        >
          {plantas.map((planta, indx) => (
            <div key={indx}>
              <CardPlantClient
                isDanfossOwned={false}
                title={planta.denominacion}
                subtitle={`${planta.cliente.razonSocial}`}
                isTest={planta.isTest}
                contents={[
                  { key: "direccion", value: planta.direccion },
                  { key: "cp", value: planta.cp },
                  { key: "poblacion", value: planta.poblacion },
                  { key: "provincia", value: planta.provincia },
                  {
                    key: "pais",
                    value: i18n.t(`country.${planta.country.name || "null"}`),
                  },
                  { key: "nDrives", value: planta.nDrivers },
                  {
                    key: "creationDate",
                    value: planta.fechaCreacion
                      ? moment(planta.fechaCreacion).format("DD/MM/YYYY")
                      : i18n.t("common.n/a"),
                  },
                ]}
                type="planta"
                className={
                  planta?.infoBySalesforce
                    ? commonStyles.cardBase
                    : commonStyles.cardBaseNotValidated
                }
                onClick={() => {
                  props.history.push(`./plantas/${planta.id}`);
                }}
                style={styles}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </RelativeBackdrop>
  );
}
